import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
// eslint-disable-next-line import/no-cycle
import { Footer, Header, SEO } from '.';
import { GlobalStyle, theme } from '../styles';
import './layout.css';

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.div`
  flex-grow: 1;
`;

const Layout = ({ title, description, image, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <LayoutWrapper>
        <SEO title={title || data.site.siteMetadata.title} description={description} image={image} />
        <Header />
        <Main>{children}</Main>
        <Footer />
      </LayoutWrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
