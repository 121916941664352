import React from 'react';
import { Box, GatsbyImage } from '../components';

export default function Notice({ img, children }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="#071526"
    >
      <Box width={{ _: '100%', md: '75%' }} p={{ _: 2, md: 4 }}>
        {children}
      </Box>
      <Box display={{ _: 'none', sm: 'block' }} width="25%" lineHeight={0}>
        <GatsbyImage src={img} alt="Notice Image" />
      </Box>
    </Box>
  );
}
