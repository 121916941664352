import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { Link } from 'gatsby';
import { media } from '../styles';

const Menu = styled.ul`
  display: flex;
  align-items: ${({ isList, alignItems }) => (isList ? 'center' : alignItems)};
  flex-direction: ${({ isList }) => (isList ? 'column' : 'row')};
  justify-content: ${({ align, isList }) =>
    isList ? 'center' : align || 'flex-start'};
  margin: 0;
  padding: 0;

  ${media.md`
    align-items: ${({ isList, alignItems }) =>
      isList ? alignItems : 'center'};
    justify-content: ${({ align }) => align || 'flex-start'};
  `}

  ${space}
`;

const List = styled.li`
  margin-right: ${({ isList }) => (isList ? '0' : '30px')};
  margin-bottom: ${({ isList }) => (isList ? '16px' : '0')};
  list-style: none;

  ${space}
`;

const MenuLink = styled(Link)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  text-decoration: none;

  &.active:before {
    opacity: 1;
    background: #f66d1d;
  }

  :hover:before {
    opacity: 0.3;
  }

  :before {
    position: absolute;
    top: -4px;
    right: -7px;
    width: 6px;
    height: 6px;
    content: '';
    transition-timing-function: ease;
    transition-duration: 0.3s;
    transition-property: background-color, opacity;
    border-radius: 100%;
    opacity: 0;
    background: #f7f9f9;
  }

  :last-child {
    margin-right: 0px;
  }
`;

export default function Nav({
  menus = [],
  align,
  alignItems = 'flex-start',
  list,
  itemspace = {},
  ...rest
}) {
  return (
    <Menu align={align} isList={list} {...rest} alignItems={alignItems}>
      {menus.map((menu, index) => (
        <List isList={list} key={index} {...itemspace}>
          <MenuLink activeClassName="active" to={menu.url}>
            {menu.label}
          </MenuLink>
        </List>
      ))}
    </Menu>
  );
}
