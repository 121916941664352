import styled, { css } from 'styled-components';
import { space, typography, color, border, layout } from 'styled-system';
import { media } from '../styles';

const Typography = styled.p`
  ${({ variant }) =>
    variant === 'heading1' &&
    css`
      font-weight: var(--semi-bold-weight);
      font-size: 24px;
      line-height: 40px;

      ${media.md`
        font-size: 45px;
        line-height: 70px;
      `}
    `}

  ${({ variant }) =>
    variant === 'heading2' &&
    css`
      font-size: 24px;
      line-height: 36px;
      font-weight: var(--bold-weight);

      ${media.md`
        font-size: 36px;
        line-height: 45px;
      `}
    `}

  ${({ variant }) =>
    variant === 'heading3' &&
    css`
      font-size: 22px;
      line-height: 36px;
      font-weight: var(--bold-weight);

      ${media.md`
        font-size: 30px;
        line-height: 40px;
      `}
    `}

  ${({ variant }) =>
    variant === 'heading4' &&
    css`
      font-size: 22px;
      line-height: 32px;
      font-weight: var(--bold-weight);

      ${media.md`
        font-size: 24px;
        line-height: 36px;
      `}
    `}

  ${({ variant }) =>
    variant === 'heading5' &&
    css`
      font-size: 16px;
      line-height: 32px;
      font-weight: var(--semi-bold-weight);

      ${media.md`
        font-size: 20px;
        line-height: 30px;
      `}
    `}

  ${({ variant }) =>
    variant === 'paragraph1' &&
    css`
      font-size: 16px;
      line-height: 32px,
      font-weight: var(--normal-weight);
    `}

  ${({ variant }) =>
    variant === 'paragraph2' &&
    css`
      font-size: 14px;
      line-height: 24px,
      font-weight: var(--normal-weight);
    `}

  ${({ variant }) =>
    variant === 'subtitle1' &&
    css`
      font-size: 13px;
      line-height: 17px;
    `}

  ${({ color }) =>
    color === 'primary' &&
    css`
      color: var(--primary-color);
    `}

  ${({ color }) =>
    color === 'light' &&
    css`
      color: var(--text-secondary-color);
    `}

  ${({ color }) =>
    color === 'textPrimary' &&
    css`
      color: var(--text-primary-color);
    `}

  ${({ color }) =>
    color === 'error' &&
    css`
      color: var(--error-color);
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
      text-underline-position: under;
    `}

  em {
    color: #008fd4;
    font-style: normal;
  }

  span {
    color: #f66d1d;
    font-style: normal;
  }

  ${space}
  ${color}
  ${typography}
  ${border}
  ${layout}
`;

export default Typography;
